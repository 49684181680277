
<template>
  <!-- 企业库管理 -->
  <div class="">
    <ax-table
      ref="logTable"
      :columns="columns"
      :show-search="true"
      :searchForm="searchForm"
      :isCard="true"
      :dataSourceApi="api.list"
      @action-column-click="actionColumnClick"
    >
    </ax-table>
    <detaila ref="detaila"></detaila>
  </div>
</template>
   
   <script>
const searchForm = [
  {
    label: "企业库管理",
    type: "",
    model: "location",
    options: {
      placeholder: "请输入企业库管理",
    },
    formItem: {},
    col: { span: 6 },
  },

];
import api from "./api";
import { initGridFormData } from "@/common/tools";
import detaila from "./components/details.vue";
export default {
  components: { detaila },
  data() {
    return {
      api,
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "企业名称",
            dataIndex: "orderSn",
            ellipsis: true,
            key: 1,
            width: 100,
          },
          {
            title: "法定代表人",
            dataIndex: "productSn",
            ellipsis: false,
            key: 2,
            width: 80,
          },
          {
            title: "企业代码",
            dataIndex: "price",
            ellipsis: true,
            key: 3,
            width: 100,
          },
          {
            title: "是否支持服务",
            dataIndex: "originalPrice",
            ellipsis: false,
            width: 100,
            key: 4,
          },
        ],
        true,
        {
          maxWidth: 100,
          actions: [
            {
              props: (record) => {
                return {
                  text: "详情",
                  type: "#E6A23C",
                  name: "detail",
                  link: true,
                  // disabled: !(record.status === "1"),
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "delete",
                  type: "#f95a5a",
                  link: true,
                  popDisabled: false, //delete是false是禁用
                  // disabled: !(record.status === "1"),
                };
              },
            },

          ],
        }
      ),
      searchForm: initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 100,
      }),
      visible: false,
    };
  },

  methods: {
      // 操作
      async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 编辑
        case "detail":
        this.$refs.detaila.openModal(record);
          break;
      }
    },
    // 表格刷新
    refresh() {
      this.$refs.logTable.getDataSource();
    },
    // 启用状态

  },

  created() {},

  mounted() {},
};
</script>
   <style lang='less' scoped>
</style>